<template>
  <div>
    <b-container fluid>
      <button-back />
      <b-row>
        <b-col cols="12">
          <h3>Tours</h3>
          <ButtonTutotial :module="module" />
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col v-if="hasPermission(module, 'new')" lg="12" md="12" sm="12" class="mb-2">
          <b-button size="sm" variant="success" @click="handleCreating">
            <b-icon-plus-square class="mr-1" />
            Nuevo
          </b-button>
        </b-col>
      </b-row>
      <section class="mb-2">
        <color-indicator :items="items" @filterEvents="filterEvents" />
        <calendar-travel :events="events" :isBusy="isBusy" @search="findDateStartAndDateEnd" />
      </section>
    </b-container>

    <base-modal :title="title" :id="id">
      <template v-slot:form>
        <form-travel :record="record" :isEdit="isEdit" :isDisabled="isDisabled" @save="save" />
      </template>
    </base-modal>
  </div>
</template>

<script>
import TravelMixin from "@/mixins/TravelMixin";
// Components
import {
  BContainer,
  BCol,
  BRow,
  BButton,
  BIconPlusSquare,
} from "bootstrap-vue";
import BaseModal from "@/components/base/BaseModal";
import FormTravel from "./components/FormTravel";
import CalendarTravel from "@/components/calendar/CalendarTravel";
import ButtonBack from "@/components/buttons/ButtonBack";
import ColorIndicator from "@/components/globals/ColorIndicator";
import ButtonTutotial from "@/components/buttons/ButtonTutotial";
export default {
  components: {
    BContainer,
    BCol,
    BRow,
    BButton,
    BIconPlusSquare,
    BaseModal,
    FormTravel,
    CalendarTravel,
    ButtonBack,
    ColorIndicator,
    ButtonTutotial,
  },
  mixins: [TravelMixin],
  data() {
    return {
      isCall: true,
    }
  }
};
</script>
